<template>
  <div class="font-outfit">
    <main class="container py-5">
      <div class="row flex">
        <div class="col-md-6 px-3 py-3">
          <h1 class="py-3" style="color:#58595B;font-size: 2em;">Account Deletion Request</h1>
          <p>To request deletion of your Bookabook account, please contact us via WhatsApp. Our support team will assist you with the account deletion process.</p>
          <p>Before proceeding with account deletion, please note:</p>
          <ul class="mb-4">
            <li>Account deletion is permanent and cannot be undone</li>
            <li>All your data and purchase history will be removed</li>
            <li>Any active orders will need to be completed first</li>
          </ul>
          <base-button
            block
            type="primary" 
            @click="openWhatsApp"
            style="background-color: #25D366;border:none;width: fit-content;"
          >
            <span>Contact via WhatsApp</span>
          </base-button>
        </div>
        <div class="col-md-6 px-3 py-3">
          <img class="img-fluid" src="/img/mascot-help.png" alt="Support mascot">
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  methods: {
    openWhatsApp() {
      const message = "Hello, I would like to request deletion of my Bookabook account."
      const whatsappUrl = `https://web.whatsapp.com/send?phone=6288801850011&text=${encodeURIComponent(message)}`
      window.open(whatsappUrl, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/colors';

.section {
  margin-bottom: 48px;

  &__title {
    font-family: 'Outfit', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: $blue;
  }

  &__subtitle {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: hsla(0, 0%, 0%, 0.7);
  }

  &__paragraph {
    font-size: 12px;
    color: hsla(0, 0%, 0%, 0.6);
  }

  &__divider {
    margin-top: 0px;
    margin-bottom: 16px;
  }

  &__figure {
    margin: 0 auto;
    width: 200px;
    max-width: 100%;
    margin-bottom: 28px;
  }

  &__figure-img {
    width: 100%
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 72px;

    &__title {
      font-size: 32px;
    }

    &__subtitle {
      font-size: 24px;
    }

    &__paragraph {
      font-size: 16px;
    }

    &__divider {
      margin-bottom: 64px;
    }

    &__figure {
      width: 668px;
      margin-bottom: 36px;
    }
  }
}
</style>